import React from 'react'
import { Toggle } from '@buffer-mono/legacy-bufferapp-components'
import { Text } from '@bufferapp/ui'
import { Row } from '~publish/legacy/shared-components'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import PreferencesRow from '../PreferencesRow'
import type { TwoFactorAuthProps } from '~publish/legacy/two-factor-auth/types'

const TwoFactorAuth = ({
  machineState,
  isEnabled,
  method,
  phoneNumber,
  updatePhoneNumber,
  editMode,
  recoveryCode,
  transition,
  setPhoneNumber,
  submitPhoneNumber,
  loading,
  error,
  setupApp,
  qrCode,
  updateMethod,
  submitCode,
  handleRecoveryCodeSelect,
  initKey,
  email,
}: TwoFactorAuthProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Row>
      <div
        style={{
          marginRight: '1rem',
        }}
      >
        <Text type="h3">
          {t('preferences.security.twoFactorAuthentication')}
        </Text>
        <div
          style={{
            marginTop: '0.5rem',
            maxWidth: '700px',
          }}
        >
          <PreferencesRow
            // @ts-expect-error TS(2322) FIXME: Type '{ machineState: string; transition: (arg0: s... Remove this comment to see the full error message
            machineState={machineState}
            transition={transition}
            method={method}
            phoneNumber={phoneNumber}
          />
          <Modal
            machineState={machineState}
            transition={transition}
            updatePhoneNumber={updatePhoneNumber}
            setPhoneNumber={setPhoneNumber}
            submitPhoneNumber={submitPhoneNumber}
            loading={loading}
            error={error}
            setupApp={setupApp}
            qrCode={qrCode}
            updateMethod={updateMethod}
            submitCode={submitCode}
            handleRecoveryCodeSelect={handleRecoveryCodeSelect}
            editMode={editMode}
            recoveryCode={recoveryCode}
            initKey={initKey}
            email={email}
          />
        </div>
      </div>
      <div
        style={{
          paddingRight: '0.5rem',
        }}
      >
        <Toggle
          on={isEnabled}
          onText={t('common.enabled')}
          offText={t('common.disabled')}
          onClick={(): void => transition(isEnabled ? 'DISABLE' : 'ENABLE')}
          disabled={!['enabled', 'disabled'].includes(machineState)}
          size="mini"
        />
      </div>
    </Row>
  )
}

export default TwoFactorAuth
