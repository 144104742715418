import React from 'react'
import { Toggle } from '@buffer-mono/legacy-bufferapp-components'
import { Row } from '~publish/legacy/shared-components'
import { Text } from '@bufferapp/ui'
import { useTranslation } from 'react-i18next'
import styles from './Notification.module.css'

type NotificationProps = {
  title: string
  description?: string
  onToggleClick: (toggleIsEnabled: boolean, type: string) => void
  toggleIsEnabled: boolean
  type: string
}

const Notification = ({
  title,
  description,
  onToggleClick,
  toggleIsEnabled,
  type,
}: NotificationProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Row>
      <div>
        <Text type="h3">{title}</Text>
        {description && <Text type="p">{description}</Text>}
      </div>
      <div className={styles.switchStyle}>
        <Toggle
          onText={t('common.enabled')}
          offText={t('common.disabled')}
          on={toggleIsEnabled}
          size="mini"
          onClick={() => onToggleClick(!toggleIsEnabled, type)}
        />
      </div>
    </Row>
  )
}

export default Notification
